<template>
  <v-container>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" id="toolbar-title">
              {{ $refs.calendar.title | capitalize }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
              <v-chip class="ma-2" color="primary" text-color="white">
                Total congé consommé: <span>&nbsp;</span
                ><strong> {{ totalCongeDays }} jours</strong>
              </v-chip>
              <v-chip class="ma-2" color="#e0004d" text-color="white">
                Total congé sans solde: <span>&nbsp;</span
                ><strong>{{ totalSansSolde }} jours</strong>
              </v-chip>
              <v-chip class="ma-2" color="secondary" text-color="white">
                Totale autorisation consommée:
                <span>&nbsp;</span
                ><strong>{{ totalAutorisationHours }} heures</strong>
              </v-chip>
            </div>
          </v-toolbar>
        </v-sheet>

        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            type="month"
            @click:event="eventClick"
            locale="fr"
            event-name="name"
            :weekdays="weekday"
          >
            <template v-slot:day-label="{ date }">
              <div class="wrapper">
                <span class="day-name">{{ date | moment("dddd") }}</span
                ><br />
                <span class="day-date">{{ date | moment("DD/MM/YYYY") }}</span>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>

      <v-dialog v-model="eventDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="justify-space-between">
            <span class="headline">Détails du congé</span>
            <v-btn color="black" dark icon right @click="close">
              <v-icon color="#B2BBAB">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="congeHeaders"
              :items="[selectedEvent]"
              :items-per-page="Infinity"
              hide-default-footer
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr>
                    <td>{{ items[0].name }}</td>
                    <td>{{ items[0].details }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="pb-3 pt-0">
            <v-spacer></v-spacer>
            <Button action_name="Fermer" color="secondary" @clicked="close" />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { Button } from "@/components/base";
import { getEventsAgent } from "@/services/congeAgent.api";
export default {
  components: { Button },
  methods: {
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
      const endDate = this.focus;
      this.getEventsAgentList(endDate);
    },
    next() {
      this.$refs.calendar.next();
      const endDate = this.focus;
      this.getEventsAgentList(endDate);
    },
    getEventsAgentList(endDate) {
      getEventsAgent(endDate).then((response) => {
        this.events = response.data.events;
        this.totalCongeDays = response.data.totalCongeDays;
        this.totalAutorisationHours = response.data.totalAutorisationHours;
        this.totalSansSolde = response.data.totalSansSolde;
      });
    },

    eventClick({ event }) {
      this.selectedEvent = event;
      this.eventDialog = true;
    },
    close() {
      this.eventDialog = false;
    },
  },
  data() {
    return {
      events: [],
      congeHeaders: [
        { text: "Nom", sortable: false },
        { text: "Détails", sortable: false },
      ],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      focus: "",
      totalCongeDays: 0,
      totalSansSolde: 0,
      totalAutorisationHours: 0,
      selectedEvent: {},
      eventDialog: false,
    };
  },
  mounted() {
    const endDate = this.$refs.calendar.lastEnd.date;
    this.getEventsAgentList(endDate);
    this.$refs.calendar.checkChange();
  },
};
</script>

<style scoped>
.month {
  font-family: "Segoe UI", serif;
  font-size: 33px;
  color: #003d42;
}

.cols-aligned {
  align-self: flex-end;
}
.theme--light.v-calendar-weekly {
  cursor: pointer;
}
.v-calendar .v-event {
  background: transparent;
}
.v-calendar-weekly__day {
  height: 100px;
}

.wrapper {
  text-align: left;
  margin-left: 15px;
}
.day-name {
  font-family: "Segoe UI", serif;
  font-size: 24px;
  color: #003d42;
}
.day-date {
  font-family: "Segoe UI", serif;
  font-size: 20px;
  color: #003d42;
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__day {
  height: 115px !important;
}
::v-deep .v-calendar-weekly__head {
  display: none !important;
}
</style>
